import { Brands } from 'contexts/CompanyContext'

export const AETHIR_CODES = {
  '393938': true,
  '@liaoblove': true,
  '02han': true,
  '0xaur': true,
  '0xcdresearch': true,
  '0xgpu': true,
  '0xhobo_aethir': true,
  '0xmrdq': true,
  '1000iq': true,
  '10offaethir': true,
  '1min': true,
  '1minute': true,
  '2tictac2': true,
  '3nyoomkongz': true,
  '3poch2025': true,
  '7708youko': true,
  '98kdao': true,
  'a-z lowercase': true,
  a2finance: true,
  a2pad: true,
  a8_18: true,
  aa12345678: true,
  abcde1234: true,
  abel: true,
  abhi: true,
  abruzy: true,
  achen: true,
  acquirefi: true,
  adm: true,
  aethermoon: true,
  aethir_nodes_purechaincapital: true,
  'aethir.ers.crypto': true,
  aethir2moon: true,
  aethirnode: true,
  aethirnodos: true,
  afwg1114: true,
  ag: true,
  aiapaekkongz: true,
  aidenjohnson: true,
  airdropcrypto: true,
  airdroplist: true,
  airdrops: true,
  ak: true,
  al: true,
  alanrog: true,
  alekseipetrov: true,
  alevator: true,
  alexcrypto: true,
  alexeiivanov: true,
  alexeitanaka: true,
  alexzhang: true,
  alfadao: true,
  alienswap2024: true,
  alphatrace: true,
  alphax: true,
  altcoindaily: true,
  altcryptogems: true,
  altology: true,
  ameercoinstore11: true,
  aminlams: true,
  anderson2024: true,
  andreiivanov: true,
  andy: true,
  angelo: true,
  animocaventures: true,
  anson666: true,
  ap3rture: true,
  apac_partner: true,
  apaccn: true,
  apacjp: true,
  apackr: true,
  apacwink: true,
  aphexkongz: true,
  apple: true,
  arc: true,
  arndxt: true,
  arthurg: true,
  arturguimaraesp: true,
  arturkongz: true,
  as: true,
  ashcrypto: true,
  astronaut: true,
  at: true,
  ath2: true,
  atm: true,
  aussiecapital: true,
  avachen: true,
  aw: true,
  axcrypto: true,
  axel: true,
  ayasuzuki: true,
  aylo: true,
  az: true,
  aza: true,
  b2188: true,
  baby: true,
  bacon: true,
  baekho: true,
  baeko: true,
  baiye9958: true,
  bamil: true,
  basedvc: true,
  bbh: true,
  bblock: true,
  bci: true,
  bcinnovations: true,
  bcspc: true,
  bde: true,
  bdweb3: true,
  beanstock: true,
  belabs: true,
  ben_g: true,
  beriheng168: true,
  berkut: true,
  bhmain20: true,
  bhventure: true,
  bigmferkongz: true,
  binanciansvn: true,
  bipzy: true,
  bitaxis: true,
  bitcoach: true,
  bitcoinaddict: true,
  bitedge: true,
  bitgenius: true,
  bitlogic: true,
  bitmatrix: true,
  bitnest: true,
  bitnova: true,
  bitpan: true,
  bitpulse: true,
  bitquest: true,
  bitsecure: true,
  bittrust: true,
  bitworks: true,
  blackbeard: true,
  blackdragon: true,
  blaze420_nodes: true,
  blg: true,
  blockcore: true,
  blockfund: true,
  blockgenius: true,
  blockhead: true,
  blocklogic: true,
  blockmatrix: true,
  blocknova: true,
  blockprime: true,
  blockpulse: true,
  blocksecure: true,
  blocktech: true,
  blocktvbee: true,
  blockwave: true,
  blockwise: true,
  blokdrops: true,
  bluezulukongz: true,
  bmg: true,
  bmt: true,
  bobkonczak: true,
  bojiwkongz: true,
  bojoalpha: true,
  bolt: true,
  bossmj: true,
  boxmining: true,
  bpexchange: true,
  brianhuang: true,
  broleonaus: true,
  bryanross: true,
  bryanrosswins: true,
  bslabs: true,
  btc: true,
  btc100k: true,
  btcapital: true,
  btcdayu2024: true,
  btr: true,
  buildervc: true,
  bullrun: true,
  butyobutyobutyo: true,
  bwlabs: true,
  bz: true,
  c4: true,
  cagy: true,
  calman: true,
  capta: true,
  cath: true,
  cci: true,
  ccl: true,
  cct: true,
  cctamil100: true,
  cek: true,
  celabs: true,
  cen_1234: true,
  central: true,
  cerberus: true,
  cgdoge: true,
  cgtech: true,
  chacha: true,
  chain_broker: true,
  chainbolt: true,
  chaincore: true,
  chainedge: true,
  chaingen: true,
  chainglobe: true,
  chainkey: true,
  chainmatrix: true,
  chainprime: true,
  chainpulse: true,
  chainsecure: true,
  chainspark: true,
  chainvault: true,
  chainworks: true,
  chargedcrypto: true,
  charlesaethirnode: true,
  chase: true,
  cheese: true,
  cheongchun: true,
  chicokongz: true,
  chinn13991: true,
  chloechen: true,
  chloewu: true,
  choonsik: true,
  chosun: true,
  chukapi: true,
  churin: true,
  cindywu: true,
  cishanjia: true,
  ck: true,
  cktajith: true,
  claske: true,
  clinnovations: true,
  cllabs: true,
  'clube das alts': true,
  clubedasalts: true,
  clube_das_alts: true,
  clubehope: true,
  cmgroup: true,
  cmsolutions: true,
  cnexchange: true,
  cntech: true,
  coach: true,
  coachk: true,
  cobacknam: true,
  cobraty: true,
  coffeekongz: true,
  coinbolt: true,
  coinchain: true,
  coinedge: true,
  coinglobe: true,
  coinlogic: true,
  coinm: true,
  coinnest: true,
  coinp: true,
  coinquest: true,
  coinspark: true,
  cointech: true,
  cointrust: true,
  cointv: true,
  coinvault: true,
  coinwise: true,
  coinworks: true,
  cookies: true,
  corgil: true,
  cornethaethirnode: true,
  coso: true,
  cpexchange: true,
  cpi_17: true,
  crescendo: true,
  criptomind: true,
  crocy: true,
  cruzcontrol: true,
  crypto_a2z: true,
  crypto_earn_important: true,
  cryptobanter: true,
  cryptobolt: true,
  cryptochasers: true,
  cryptodep: true,
  cryptodiffer: true,
  cryptodifferua: true,
  cryptofundamentals: true,
  cryptofundraisingua: true,
  cryptogen: true,
  cryptogenius: true,
  cryptohashkongz: true,
  cryptohe: true,
  cryptokid: true,
  cryptolaxy: true,
  cryptoleads: true,
  cryptolink: true,
  cryptologic: true,
  cryptomakase: true,
  cryptomatrix: true,
  cryptomedic4_gpu_nodes: true,
  cryptomocho: true,
  crypton: true,
  cryptonauts: true,
  cryptonick: true,
  cryptonova: true,
  cryptoogs: true,
  cryptoonline: true,
  cryptoowl: true,
  cryptopia_group: true,
  cryptoprime: true,
  cryptoquest: true,
  cryptorand: true,
  cryptospark: true,
  cryptostache: true,
  cryptotalks: true,
  cryptotelugu: true,
  cryptotimes: true,
  cryptotony: true,
  cryptotrust: true,
  cryptourist: true,
  cryptoverse: true,
  cryptovibe: true,
  cryptovietinfo: true,
  cryptowise: true,
  csexchange: true,
  cspdao: true,
  cstech: true,
  ctcapital: true,
  cvtech: true,
  cwlabs: true,
  cwsolutions: true,
  cwtech: true,
  cyberkongz: true,
  cygnoides: true,
  d7: true,
  dahua: true,
  dahuzi: true,
  daichiyamamoto: true,
  dailybear: true,
  daku: true,
  dan: true,
  daniel: true,
  davidportnoy: true,
  davidwang: true,
  daxigua: true,
  dc: true,
  dca: true,
  dci2024: true,
  dd: true,
  ddadon: true,
  deaflow: true,
  'defi.enigma1': true,
  defigen: true,
  defiprime: true,
  defisaint: true,
  defiscamcheck: true,
  degen: true,
  degenclan: true,
  degenlegends: true,
  delarg0: true,
  denischubchik: true,
  dewhalescapital: true,
  dexcheck_ai: true,
  dextforce: true,
  dg: true,
  digitalaxis: true,
  digitalbolt: true,
  digitalcore: true,
  digitaledge: true,
  digitalglobe: true,
  digitalhub: true,
  digitallink: true,
  digitalmatrix: true,
  digitalpulse: true,
  digitalquest: true,
  digitalspark: true,
  digitaltrust: true,
  ding: true,
  dingalingts: true,
  discount10: true,
  dkongkongz: true,
  dlg: true,
  dli: true,
  dmg: true,
  dmitryivanov: true,
  dmitrytanaka: true,
  doclub: true,
  doctor: true,
  dogin: true,
  donphano: true,
  doosin: true,
  doro: true,
  dorrego: true,
  double: true,
  doubletop: true,
  dougykongz: true,
  dropsanalytics: true,
  dstech: true,
  dtcorp: true,
  dusty: true,
  dvtech: true,
  dylancoinstore16: true,
  e6ycrypto: true,
  eaglecrew: true,
  ec: true,
  eclipsefi: true,
  eg: true,
  ej: true,
  ek: true,
  el: true,
  elisa: true,
  elizabeth_elabs: true,
  elizabeth_elabs10: true,
  elizabeth_elabs11: true,
  elizabeth_elabs12: true,
  elizabeth_elabs13: true,
  elizabeth_elabs14: true,
  elizabeth_elabs15: true,
  elizabeth_elabs16: true,
  elizabeth_elabs17: true,
  elizabeth_elabs18: true,
  elizabeth_elabs19: true,
  elizabeth_elabs2: true,
  elizabeth_elabs3: true,
  elizabeth_elabs4: true,
  elizabeth_elabs5: true,
  elizabeth_elabs6: true,
  elizabeth_elabs7: true,
  elizabeth_elabs8: true,
  elizabeth_elabs9: true,
  elontrades: true,
  elyar: true,
  emilyli: true,
  emilynguyen: true,
  emmazhang: true,
  empty: true,
  enefteasemekongz: true,
  enobeikongz: true,
  enryu: true,
  enso: true,
  ericisntme: true,
  erscrypto: true,
  es: true,
  et: true,
  ethandefi: true,
  ethanwang: true,
  eurosniper: true,
  evans: true,
  everseed: true,
  exitliqcapital: true,
  explo: true,
  ezcrypto: true,
  fangwei: true,
  fatcats: true,
  fepercigo: true,
  fernkongz: true,
  figfig: true,
  financialnodes: true,
  finsight: true,
  fireant: true,
  fishmarketacad: true,
  fointcrypto: true,
  follis: true,
  fomo: true,
  fomofomo: true,
  foresightnews: true,
  frid5696: true,
  fridge: true,
  fusionseven: true,
  g3: true,
  gala8329: true,
  gallagher: true,
  gckongz: true,
  gemhi: true,
  gemhivedao: true,
  getcoin: true,
  getmoni: true,
  gg: true,
  gibsteak: true,
  giu: true,
  gjtszkbv: true,
  glassnode: true,
  gm: true,
  good77: true,
  gorochi: true,
  gp: true,
  gpuwifhat: true,
  gracenguyen: true,
  grail: true,
  gs: true,
  gts: true,
  gudpartners: true,
  guildfi: true,
  guildqb: true,
  hanasuzuki: true,
  harecrypta: true,
  haskell: true,
  hbeeee: true,
  heedan: true,
  heedan2: true,
  hellosuoha: true,
  heroly88: true,
  hinapark: true,
  hiroba: true,
  hiroshitanaka: true,
  hirototanaka: true,
  hmalviya9: true,
  holysheet: true,
  honeybottle: true,
  honeyrat: true,
  hoogagaming: true,
  house_of_crypto: true,
  hustle: true,
  hw: true,
  hyeonwoolee: true,
  hz38dherf: true,
  hzgb: true,
  'i need a code': true,
  ineedacode: true,
  iba: true,
  ice: true,
  iced: true,
  icesteam: true,
  icobeast: true,
  icodrops: true,
  icosharks: true,
  identifykongz: true,
  ik: true,
  il: true,
  ilmeaalim: true,
  imi520: true,
  in: true,
  incrypted: true,
  informdao: true,
  insalubres: true,
  insects: true,
  insightful: true,
  investruiz: true,
  invokokongz: true,
  ip: true,
  itscryptoaddict: true,
  ivan: true,
  ivanivanov: true,
  ivanpetrov: true,
  jableskongz: true,
  jack: true,
  jackbtc: true,
  jakejohnson: true,
  james: true,
  james_banter: true,
  jamesgensnote: true,
  jc: true,
  jenti: true,
  jeongnam: true,
  jero: true,
  jerryalpha: true,
  jerrys: true,
  jerryview: true,
  jg: true,
  jialiu: true,
  jiholee: true,
  jiko: true,
  jiminlee: true,
  jimmyshequ: true,
  jingliu: true,
  jinkang: true,
  jinpark: true,
  jiqqy: true,
  jiventures: true,
  jl: true,
  jm: true,
  jmr: true,
  jn: true,
  joe: true,
  joeparys: true,
  john5cripto: true,
  johnfdong: true,
  join2here: true,
  jonnyvalue: true,
  jordi: true,
  jp: true,
  jr: true,
  juan: true,
  jutro: true,
  jw: true,
  jz: true,
  k0099: true,
  k1234: true,
  kaitoyamamoto: true,
  kaizen: true,
  kartel_aethir: true,
  kartik: true,
  kbn0vc_cryptonairz: true,
  kenjiyamamoto: true,
  kentatanaka: true,
  kesler: true,
  keyur: true,
  kfcdao: true,
  kibi: true,
  killberos: true,
  kincat: true,
  kingster: true,
  kirbycrypto: true,
  kit_gpt: true,
  kobe: true,
  kolcapital: true,
  kong2love: true,
  kongy: true,
  koob: true,
  kopadze: true,
  kortankongz: true,
  kriptomessi: true,
  kuiclub: true,
  kyle: true,
  kyroh: true,
  l1020688: true,
  ladycrypto99: true,
  laibookongz: true,
  lambe_kripto99: true,
  laolu: true,
  layerhub: true,
  lc: true,
  leaf: true,
  legoleecm: true,
  leokim: true,
  lesoza1: true,
  levelnode: true,
  lexmorenoweb3: true,
  lg: true,
  lianyanshe: true,
  liaoblove: true,
  lilychen: true,
  lilynguyen: true,
  lilytoken: true,
  lilywang: true,
  limbo: true,
  linchen: true,
  lingwei: true,
  liquidace: true,
  lk: true,
  ll: true,
  lmecripto: true,
  ln: true,
  loc: true,
  loldemortkongz: true,
  loltapeskongz: true,
  lordjorx: true,
  loukongz: true,
  lovejudylee: true,
  lp: true,
  luball13: true,
  lubi: true,
  lunakim: true,
  lunawu: true,
  m6labs: true,
  mack: true,
  mad: true,
  madmax420: true,
  magic: true,
  'main/#': true,
  mario: true,
  mark888: true,
  marshall: true,
  martainss: true,
  masil: true,
  masiwei: true,
  master_d: true,
  matheusbordin: true,
  maxpump: true,
  maxwang: true,
  mb: true,
  meilin: true,
  meinft: true,
  mesgo: true,
  metaboo: true,
  metabros47: true,
  metamuffin: true,
  mff: true,
  mg: true,
  miachen: true,
  miali: true,
  mianguyen: true,
  miawu: true,
  michaelgo: true,
  michaelwang: true,
  mikhailpetrov: true,
  mingo: true,
  mini: true,
  minjikim: true,
  mintair: true,
  mintttch: true,
  mirrordao: true,
  ml: true,
  mocaverse: true,
  moer: true,
  moneygrid: true,
  monki: true,
  montekripto: true,
  moon1ightst: true,
  moonbootsdao: true,
  morrison: true,
  mrdefi: true,
  mrseanvvv: true,
  mt: true,
  murphy: true,
  murphybtc: true,
  muyi: true,
  mvp: true,
  mvst99: true,
  mw: true,
  mychelmendes: true,
  myhobby: true,
  na: true,
  naback222_: true,
  naback7366: true,
  'nansen-alpha': true,
  neotokyo: true,
  nero_001: true,
  ng: true,
  nikitaivanov: true,
  nithkongz: true,
  nk: true,
  nobs: true,
  node: true,
  node_sale: true,
  nodeops: true,
  noder: true,
  noders: true,
  nomadkongz: true,
  nomoonnobuy: true,
  none: true,
  notorius: true,
  notsezkongz: true,
  np: true,
  nt: true,
  nzresearch: true,
  oduvanchik: true,
  oignodes01: true,
  ok: true,
  olimpo: true,
  olivianguyen: true,
  omg: true,
  onelykim: true,
  onstan: true,
  orangie: true,
  os: true,
  osa3210: true,
  osushi: true,
  ot: true,
  otaku: true,
  ourocap: true,
  ow: true,
  pablo007: true,
  pagu: true,
  paladins: true,
  pandario: true,
  pangpang: true,
  panic: true,
  pareh: true,
  pastanaga: true,
  pastel: true,
  patocrypto: true,
  pegas: true,
  pengu: true,
  pepe_fonji: true,
  pgcapital: true,
  pmzcapital: true,
  pnxgrp: true,
  pokakongz: true,
  polkadotnews: true,
  pratas: true,
  praximal: true,
  prestito: true,
  primecrypto: true,
  procent: true,
  pursebox: true,
  qingqingge: true,
  raiden: true,
  rakusu148: true,
  ramoncunha: true,
  randomwalk010: true,
  real18nom: true,
  realagency: true,
  rechinballkongz: true,
  redox: true,
  refcode: true,
  rentanaka: true,
  res: true,
  retrodao: true,
  rice: true,
  richtrain: true,
  risenode: true,
  riyankongz: true,
  rk: true,
  rl: true,
  rocinante: true,
  rocky_bitcoin: true,
  'ronja88!!eberswald': true,
  rosckongz: true,
  rowna: true,
  rp: true,
  rph: true,
  rs: true,
  rui2333: true,
  runsensen: true,
  rw: true,
  ryabina: true,
  ryand: true,
  saamon: true,
  safz: true,
  sakipark: true,
  samuelwang: true,
  samurai_starter: true,
  samurai10: true,
  sanctuary: true,
  sanctuskongz: true,
  sandro: true,
  sandrocabrales: true,
  sanjay: true,
  sanyuan222: true,
  satoshisuzuki: true,
  satoshitanaka: true,
  satoshiyamamoto: true,
  satstack: true,
  savage: true,
  scaleswapua: true,
  sct: true,
  searchfi: true,
  secretodefi: true,
  seedify: true,
  sefatuba3: true,
  semko: true,
  sensei: true,
  sepower: true,
  seunghoonlee: true,
  shaimo: true,
  shcornkongz: true,
  shengart13: true,
  shinchan: true,
  shiv: true,
  shun: true,
  silverduck: true,
  sirajkongz: true,
  sixth0302: true,
  sk: true,
  ska: true,
  skid: true,
  sl: true,
  slow: true,
  smartliquidity: true,
  smartmoneycrypto: true,
  snooper: true,
  snowienftkongz: true,
  sober: true,
  sofiahernandez: true,
  softlipa: true,
  sohwak: true,
  soj: true,
  solanium: true,
  solidrockkongz: true,
  sophiachain: true,
  sophianguyen: true,
  sophy: true,
  sorapark: true,
  soso: true,
  spaaarkling: true,
  spare_gas_4_lambro: true,
  spartadex: true,
  spicycapital_2024: true,
  spike: true,
  sqrdao: true,
  squadwealth: true,
  ssibueong: true,
  st: true,
  starlaunch: true,
  stay: true,
  stc: true,
  stellapark: true,
  stitch2002621: true,
  strong: true,
  subcaptain: true,
  subijjang: true,
  sun1q84: true,
  sungjae: true,
  sungsil: true,
  sunmoon: true,
  suppoman: true,
  surgence: true,
  swissborg: true,
  symmetry: true,
  taejoonkim: true,
  taeyeonlee: true,
  takeshiyamamoto: true,
  takumitanaka: true,
  talkchain: true,
  tangerine: true,
  tanito: true,
  tanzent: true,
  tatsuyayamamoto: true,
  tc: true,
  tccapital: true,
  tebi: true,
  tedpillows: true,
  tekoki3: true,
  tempo: true,
  tg: true,
  tgtech: true,
  th: true,
  thecallmaster: true,
  theking1_: true,
  thewardensgc: true,
  thewoys: true,
  thiagomoura: true,
  thorin: true,
  tindorr: true,
  tk_ventures: true,
  tlsolutions: true,
  tnsolutions: true,
  tokenbolt: true,
  tokencore: true,
  tokenlink: true,
  tokenmatrix: true,
  tokenmore: true,
  tokennex: true,
  tokenplus: true,
  tokenpulse: true,
  tokenspark: true,
  tokentrust: true,
  tokenvault: true,
  tokenwise: true,
  tokenworks: true,
  tomoyamamoto: true,
  top7ico: true,
  tpcaethir: true,
  traderdaink: true,
  tsecrypto: true,
  tslabs: true,
  tssolutions: true,
  ttcapital: true,
  tvs: true,
  tylerhill: true,
  unclemarc: true,
  unicornverse: true,
  uppexinvestment2024: true,
  valhallakongz: true,
  valorem420: true,
  varnakongz: true,
  vcdefi_aethir2024: true,
  vijaypravin: true,
  vikkongz: true,
  viktorpetrov: true,
  vincentkongz: true,
  virtuealliance: true,
  vision: true,
  vladislavivanov: true,
  vsfg_2024: true,
  wabnode: true,
  waltersilva_criptocomfe: true,
  wangxiaolou: true,
  wanzi: true,
  waribiki: true,
  web3_honey: true,
  web3alertio: true,
  weichen: true,
  weili: true,
  weizhang: true,
  whatever: true,
  whitesocks: true,
  whitewaterlabs: true,
  whosthesmimikongz: true,
  william11chan: true,
  wilsen88: true,
  winkrypto: true,
  wise3_ventures: true,
  'wise3-venture': true,
  wiseadvice: true,
  wo168: true,
  wolvesdao: true,
  wtm: true,
  wxyz_vc: true,
  wyaethirnode: true,
  xializi: true,
  xiaomao: true,
  xiaoxin: true,
  xingxingjun: true,
  xxx: true,
  y010k1ng: true,
  yaikan: true,
  yc: true,
  yeolchoong: true,
  yeolcjoong: true,
  yeomyung: true,
  yeonwoo: true,
  yfl: true,
  yieldfarming: true,
  yiyixmb: true,
  yobeul: true,
  youbi: true,
  yourairdrop: true,
  yp: true,
  ytflz: true,
  yuayato: true,
  yudan: true,
  yuisuzuki: true,
  yukipark: true,
  yukisato: true,
  yukisuzuki: true,
  yunakim: true,
  yunapark: true,
  yunasuzuki: true,
  yunlog: true,
  yurisuzuki: true,
  yusuketanaka: true,
  yutiancoin: true,
  yutotanaka: true,
  yzaethirnode: true,
  z12345678: true,
  zephyruscap: true,
  zflab: true,
  zhuge123: true,
  zl: true,
  zn: true,
  zunahn: true,
  сapo69: true,
  if: true,
  impossiblefinance: true,
  impossible_finance: true,
  bokjisa: true,
  elibecislao: true,
  waltersilvacriptocomfe: true,
  deki: true,
  hinkok: true,
  bulka: true,
  cryptomera: true,
  '0xfoton': true,
  laskarcrypto: true,
  neowave: true,
  nick0906: true,
  saheb: true,
  vampire: true,
  rebeleth: true,
  cryptus: true,
  kmanus88: true,
  shrug: true,
  alpha: true,
  muke: true,
  aethir: true,
  aethirai: true,
  aethircloud: true,
  ath: true,
  athdao: true,
  athai: true,
  athcloud: true,
  aethirnetwork: true,
  aether: true,
  aither: true,
  aethire: true,
  aethirgaming: true,
  aethirdao: true,
  cryptophile: true,
  athkev: true,
  'apoorv.anand': true,
  chaincatchers: true,
  bammbamm: true,
  gmclub: true,
  fiona: true,
  eureka: true,
  aethirxamber: true,
  bb: true,
  tfl2024: true,
  oldfashion: true,
  cg: true,
  nebula: true,
  metabros: true,
  m6: true,
  wizards: true,
  escobar: true,
  capo69: true,
  fomocatcher: true,
  h168: true,
  farmorsss: true,
  crm666: true,
  daddy_69: true,
  techfanatic: true,
  aphone: true,
  hodler0xalpha: true,
  goldy: true,
  tudd_tutulabs: true,
  water119: true,
  eazer: true,
  drgems: true,
  'nansen alpha': true,
  nansenalpha: true,
  cleverse: true,
  ash: true,
  cape: true,
  ronja88_eberswald: true,
  luganodes: true,
  coworkers: true,
  thortrades8: true,
  malto: true,
  tuna_gg: true,
  keshu: true,
  moa: true,
  kay: true,
  rabbit: true,
  earn: true,
  tkpro654: true,
  gacrypto: true,
  kryptonates: true,
  easycrypto: true,
  cryptopedia: true,
  hrc: true,
  '65station': true,
  monis: true,
  proofoftalk: true,
  defiverse_01: true,
  atamert: true,
  vivanlive: true,
  yourfriendandy: true,
  jordan23: true,
  '8f': true,
  moon_capital: true,
  easeflow: true,
  ayaysee: true,
  hotspotty: true,
  mythos: true,
  savas: true,
  cryptogeek2024: true,
  du09btc_: true,
  mgg: true,
  sevacryptostart: true,
  '53club': true,
  shenz: true,
  cloud7: true,
  quantum: true,
  selected: true,
  onlygains: true,
  blocktalks_02: true,
  alliet: true,
  abc: true,
  if1: true,
  if2: true,
  if3: true,
  aiif: true,
  if4: true,
  if5: true,
  ashraf705: true,
  jongdong78: true,
  cryptovel: true,
  '0xnen01': true,
  advcapitalacademy: true,
  mc: true,
  nousi: true,
  jakawin: true,
  wilson: true,
  tanawatc: true,
  'crescendo ': true,
  'blaze420_nodes ': true,
  'moonbootsdao ': true,
  'alphax ': true,
  kondrashov: true,
  hybriddao: true,
  xaingames: true,
  crypt0_kakarot: true,
  romano_rnr: true,
  odailyaethirnode: true,
  chengziaethirnode: true,
  punkdao: true,
  animocabrands2024: true,
}

export const SOPHON_CODES = {
  sandro: true,
  coso: true,
  informdao: true,
  pagu: true,
  defigen: true,
  kong2love: true,
  jm: true,
  ouro: true,
  tradeparty: true,
  hoc: true,
  crypton: true,
  cryptoearn: true,
  degen: true,
  node: true,
  gagarin_crypto: true,
  eazer: true,
  cryptotelugu: true,
  andy: true,
  fomowiki: true,
  sepower: true,
  pengu: true,
  yeonwoo: true,
  icodrops: true,
  sophon_th: true,
  xingpt123: true,
  gg: true,
  sophon_gcrod: true,
  jutro2024: true,
  sophon_jiko: true,
  bean: true,
  magic: true,
  alexae_001: true,
  pang: true,
  cheongchun: true,
  gamingtokens: true,
  good77: true,
  rich: true,
  jerryv: true,
  sophon_retrodao: true,
  jammin: true,
  bitcoach: true,
  sunmoon: true,
  montekriptokontu: true,
  kfclovesophon: true,
  coach: true,
  cryptodiffer: true,
  chainbroker: true,
  cryptogics: true,
  chosun: true,
  mini: true,
  shaimo: true,
  joinhere: true,
  jiajia: true,
  incrypted: true,
  alphador: true,
  oduvanchik: true,
  discount10: true,
  arcanum_ventures: true,
  selected: true,
  a2finance: true,
  bokjisa: true,
  layerhub: true,
  coinmuhendisi: true,
  hmalviya9: true,
  kmanus88: true,
  whitelist1: true,
  stc: true,
  sophon_cartel: true,
  catcher: true,
  swiper: true,
  w3coins: true,
  heedan: true,
  sohwak: true,
  choonsik: true,
  seroy: true,
  if: true,
  impossible: true,
  impssiblefinance: true,
  core_9999: true,
  koreaalphadegen: true,
  cygnoides: true,
  gemhivedao: true,
  legoleecm: true,
  tebi: true,
  yobeul: true,
  elizabeth_elabs: true,
  moonyu: true,
  rc: true,
  kesler: true,
  '02han': true,
  cryptophile: true,
  sqrdao: true,
  noders: true,
  bdinweb3: true,
  itscryptoaddict: true,
  nomoonnobuy: true,
  cryptodep: true,
  jikoba: true,
  cryptovietinfo: true,
  pionero: true,
  a01k: true,
  doctor: true,
  ga_crypto24: true,
  wisekim_eth: true,
  uejj: true,
  cryptus: true,
  sophon_igor97: true,
  doubletop: true,
  chacha: true,
  yuayato: true,
  nodeops: true,
  bmt: true,
  polkadot_news: true,
  ssibueong: true,
  praximal: true,
  sophon_max992: true,
  'lsp.eth': true,
  ilmeaalim: true,
  wabnode: true,
  jiraiya: true,
  ca: true,
  nodehodler: true,
  serp1337: true,
  bitsearch: true,
  rui: true,
  sophon_hellosuoha: true,
  ortegas: true,
  bitpan: true,
  thewoys: true,
  cobacknam: true,
  sophon000: true,
  kgodel: true,
  xjf92dc: true,
  arcadia: true,
  kong: true,
  roxinft: true,
  bananamilk: true,
  ibcig: true,
  ddadon: true,
  rowna: true,
  satoshi: true,
  wizardofsoho: true,
  leoresearch: true,
  easeflow: true,
  dbh: true,
  dropstab: true,
  dropscapital: true,
  sincero: true,
  sungsil: true,
  malto: true,
  sophon: true,
  sophon1: true,
  sophon2: true,
  sophon3: true,
  sophon4: true,
  sophon5: true,
  sophon6: true,
  sophon7: true,
  sophon8: true,
  sophon9: true,
  sophon10: true,
  nodesale1: true,
  nodesale2: true,
  nodesale3: true,
  nodesale4: true,
  nodesale5: true,
  nodesale6: true,
  nodesale7: true,
  nodesale8: true,
  nodesale9: true,
  nodesale10: true,
  nodesale11: true,
  aethir: true,
  ath: true,
  impossiblefinance: true,
  arc: true,
  wizards: true,
  parisdaox10: true,
  boolish: true,
  zentry: true,
  sct: true,
}

const CARV_CODES = {
  carvnode: true,
  doubletop: true,
  sandro: true,
  dewhalescapital: true,
  loc: true,
  yudan: true,
  coso: true,
  ourocap: true,
  house_of_crypto: true,
  pagu: true,
  cryptotelugu: true,
  beanstock: true,
  blackdragon: true,
  jm: true,
  andy: true,
  cspdao: true,
  sepower: true,
  informdao: true,
  yp: true,
  soj: true,
  cheongchun: true,
  doctor: true,
  gemhi: true,
  central: true,
  defigen: true,
  aethir2moon: true,
  sunmoon: true,
  eazer: true,
  aylo: true,
  metamuffin: true,
  defiscamcheck: true,
  degen: true,
  pengu: true,
  shrug: true,
  laolu: true,
  kong2love: true,
  tebi: true,
  crypto_earn_important: true,
  jiko: true,
  oduvanchik: true,
  alphatrace: true,
  nzresearch: true,
  kongy: true,
  cryptochasers: true,
}

export const PROMO_CODES = {
  [Brands.AETHIR]: AETHIR_CODES,
  [Brands.SOPHON]: SOPHON_CODES,
  [Brands.CARV]: CARV_CODES,
}
