import { Box } from '@mui/material'
import { useTranslation } from 'contexts/Localization'
import React from 'react'
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'

const Container = styled.div`
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);

  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
`

const icon = (color1, color2) => {
  return (
    <svg width="101" height="22" viewBox="0 0 101 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.520854 0H100.478C100.478 0 100.864 0.982514 98.4763 0.982514C96.0889 0.982514 96.2692 3.47368 96.2692 3.47368V7C96.2692 15.2843 89.5535 22 81.2692 22H19.7296C11.4453 22 4.72957 15.2843 4.72957 7V3.47368C4.72957 3.47368 4.83093 0.991367 2.49272 0.991367C0.15451 0.991367 0.520854 0 0.520854 0Z"
        fill="url(#paint0_linear_9540_64272)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9540_64272"
          x1="-1.70638"
          y1="7.12175"
          x2="100.501"
          y2="7.12175"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color1} />
          <stop offset="1" stopColor={color2} />
        </linearGradient>
      </defs>
    </svg>
  )
}

const ParticipatedBadge = () => {
  const { t } = useTranslation()
  const { theme } = useTheme()

  return (
    <Container>
      <Box>{icon(theme.sale.participatedColor1, theme.sale.participatedColor2)}</Box>
      <Box position="absolute" top="2px" textAlign="center" width="100%">
        {t('Participated')}
      </Box>
    </Container>
  )
}

export default ParticipatedBadge
